import React, { ReactElement, useEffect, useState } from 'react';
import { IBlock, IPageData, IPageProps } from '@Types/ContentfulTypes';
import { usePageTheme, useApp, useSystemMode } from '@Context/AppContext';
import '@GraphQL/fragments';
import ContentLayout from '@Src/components/sitestructure/ContentLayout';
import SEO from '@Src/components/SEO';
import ContentfulModule from '@Src/components/ContentfulModule';
import { graphql } from 'gatsby';
import LogoBanner from '@Src/components/LogoBanner';

export interface Props extends IPageProps {
  data: {
    contentfulPageTypeCampaign: IPageData;
  };
}

const CampaignIndexPage = ({ data, location, transitionStatus, pageContext }: Props): ReactElement => {
  const { title, seoSettings, theme, content } = data.contentfulPageTypeCampaign;
  let locale = 'en-US'
  const language = locale.split(/-/)[0]
  const { setTheme: setPageTheme } = usePageTheme();
  console.log(content)
  const { mode, pageType, setPageType, setPageTitle } = useApp();
  const [value, setValue] = useState(null)
  const [submit, setSubmit] = useState(false)
  const [localTransStatus, setLocalTransStatus] = useState<string>(transitionStatus);

  console.log(mode)

  useEffect(() => {
    if (pageType === 'index' && transitionStatus == 'exiting') {
      setLocalTransStatus(transitionStatus + 'Index');
    } else {
      setLocalTransStatus(transitionStatus);
    }
    if (transitionStatus == 'entering' || transitionStatus == 'entered') {
      setPageTheme(mode == 'night' ? 'dark' : 'light');
      setPageTitle(title);
      setPageType('campaign');
    }
  }, [transitionStatus]);

  useEffect(() => {
    if (transitionStatus == 'entered') {
      setPageTheme(mode == 'night' ? 'dark' : 'light');
      setPageType('campaign');
    }
  }, [mode]);

  return (
    <ContentLayout type="signup" fullWidth={true} transitionState={localTransStatus}>
      <SEO
        location={location.pathname}
        title={seoSettings?.title || title}
        description={seoSettings?.description}
        meta={seoSettings?.metaTags}
        image={seoSettings?.image}
      />
      {content &&
        content.map(
          (module: IBlock, index: number): ReactElement => (
            <ContentfulModule theme={mode} key={module.id} type={module.type} data={module} />
          )
        )}
      <div>
        <p>Company details:</p>
        <p>ALOADED AB<br />
          Bondegatan 21<br />
          116 33 Stockholm<br />
          Sweden<br />
          <br />Company Approved for Company Tax (Godkänd för F-skatt) <br />
          with national company registration number: 559124-3836<br />
          VAT SE559124383601
        </p>
      </div>
    </ContentLayout>
  );
};

export default CampaignIndexPage;

export const query = graphql`
	query ($id: String!) {
		contentfulPageTypeCampaign(id: { eq: $id }) {
			... on IPageNode {
				...PageFields
				content {
					... on IBlockNode {
						...AllBlocks
					}
				}
			}
		}
	}
`;
